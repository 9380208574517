<template>
  <div>
    <div class="wrapper">
      <h1>Result Matrix</h1>

      <div class="bg-grey-lightest rounded-medium py-10 px-4 lg:px-7 xl:py-14">
        <h2 class="text-2xl mb-8">
          {{ activeDiet.name }}
          <span class="inline-block ml-4 text-grey-dark text-xs">
            <span v-if="getSelectedProduct === 'proact360'" class="font-bold">ProAct 360<sup>TM</sup></span><span v-else class="font-bold">ProAct</span> Dose:
            <span class="text-brand-semiMedium">
              <strong class="text-lg">
                {{ activeDiet.diet_phases && activeDiet.diet_phases.length && activeDiet.diet_phases[0].dose }}
              </strong>
              g/MT
            </span>
          </span>
        </h2>

        <grid-table
          id="grid-table"
          :tableData="dietGridColumns"
        />
      </div>

      <p class="text-brand-dark anchor-wrapper text-center leading-none mt-11 mb-8 lg:m-11 xl:mt-8 xl:mb-16">
        Lets' estimate your saving

        <span
          class="icon ml-3 w-3.5 h-5"
          v-html="icons.keyboardArrows.down"
        ></span>
      </p>
    </div>

    <div class="wrapper">
      <cost-saving
        v-if="$store.state.feed.activeDiet.diet_phases && isLoadedResultMatrix"
        :options="$store.state.feed.activeDiet.diet_phases"
      />

      <p class="text-brand-dark anchor-wrapper text-center leading-none mt-11 mb-8 lg:m-11 xl:mt-8 xl:mb-16">
        Find out about your environmental impact

        <span
          class="icon ml-3 w-3.5 h-5"
          v-html="icons.keyboardArrows.down"
        ></span>
      </p>
    </div>

    <Sustainability
      v-if="$store.state.feed.activeDiet.diet_phases && isLoadedResultMatrix"
      :activeDiet="$store.state.feed.activeDiet"
    />

    <Navigation
      v-bind="{prev: {disabled: false}, next: {disabled: true, isHidden: true}}"
      @click="onNavigationClick($event, 'phases', '')"
    >
      <template #save>
        <button
          class="btn-light"
          @click="onSave"
        >
          Save Calculation
        </button>
      </template>

      <template #export>
        <button
          class="btn-light"
          @click="onExport"
        >
          Export
        </button>
      </template>
    </Navigation>

    <export
      :is-open="isOpen"
      :resultData="exportData"
      :activeDiet="activeDiet"
      @closeModal="onClose"
    ></export>
  </div>
</template>

<script>
import Navigation from '@/components/Navigation';
import NavigationMixin from '@/mixins/NavigationMixin';
import GridTable from '@/components/table/GridTable';
import CostSaving from '@/components/result/CostSaving';
import Sustainability from '@/components/result/Sustainability';
import Export from '@/components/Export';
import {v4 as uuidv4} from 'uuid';
import {mapActions, mapGetters, mapState} from 'vuex';
import WindowUnload from '@/mixins/WindowUnload';

export default {
  name: 'Result',

  mixins: [
    NavigationMixin,
    WindowUnload
  ],

  components: {
    GridTable,
    CostSaving,
    Sustainability,
    Navigation,
    Export
  },

  created() {
    if (!Object.keys(this.$store.state.feed.activeDiet).length) {
      this.$router.push({name: 'feed-view'})
      return
    }

    this.onFetchResultMatrix({
      diet: this.$store.state.feed.activeDiet,
      animal: this.activeAnimalType?.type
    }).then(() => {
      this.isLoadedResultMatrix = true
    })
  },

  data: () => ({
    isOpen: false,
    isLoadedResultMatrix: false,
    scrollTimeout: null,
  }),

  mounted() {
    window.addEventListener('scroll', this.fixedTableHead)
  },

  methods: {
    ...mapActions({
      onFetchResultMatrix: 'result/fetchResultMatrix',
      onSavePhase: 'feed/onSaveDiet'
    }),

    onSave() {
      this.onSavePhase({diet: this.$store.state.feed.activeDiet}).then(res => {
        this.$toast.open({
          message: `Diet <strong>${res.diet.name}</strong> was saved!`
        })
      })
    },

    onExport() {
      this.isOpen = true
    },

    onClose() {
      this.isOpen = false
    },

    fixedTableHead(e) {
      const gridTable = document.getElementById('grid-table')
      const gridTableStyles = window.getComputedStyle(gridTable)
      const gridTableThead = gridTable.getElementsByTagName('thead')[0]
      const gridTableOffset = this.offset(gridTable)
      const gridTableOffsetHeightWithStyles = gridTable.offsetHeight +
          gridTableOffset.top -
          gridTableThead.offsetHeight -
          parseFloat(gridTableStyles['margin-top']) -
          parseFloat(gridTableStyles['margin-bottom']) -
          parseFloat(gridTableStyles['padding-top']) -
          parseFloat(gridTableStyles['padding-bottom'])

      if (this.scrollTimeout) {
        clearTimeout(this.scrollTimeout)
      }
      this.scrollTimeout = setTimeout(() => {
        if (window.scrollY >= gridTableOffset.top && gridTableOffsetHeightWithStyles >= window.scrollY) {
          gridTableThead.style.transform = `translateY(${window.scrollY - gridTableOffset.top}px)`
        } else if (window.scrollY < gridTableOffset.top) {
          gridTableThead.style.transform = `translateY(0px)`
        }
      }, 0)
    },

    offset(el) {
      const rect = el.getBoundingClientRect()
      const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop
      return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
    }
  },

  computed: {
    ...mapState({
      exportData: state => state.result.resultMatrix.finalResults,
      activeDiet: state => state.feed.activeDiet
    }),

    ...mapGetters({
      diets: 'feed/getDiets',
      isActiveEggPoultry: 'animalType/isActiveEggPoultry',
      activeAnimalType: 'animalType/getActiveAnimalType',
      getSelectedProduct: 'productSelect/getSelectedProduct',
    }),

    dietGridColumns() {
      const data = {
        columns: [
          {
            field: 'nutrient',
            key: uuidv4(),
            title: 'nutrients',
            width: 150,
            fixed: 'left'
          },
          {
            field: 'mean',
            title: 'MEAN',
            fixed: window.innerWidth >= 1024 ? 'right' : false,
            children: [
              {
                field: 'meanContribution',
                key: uuidv4(),
                title: 'Contribution',
                width: 100
              },
              {
                field: 'meanMatrix',
                key: uuidv4(),
                title: 'Matrix',
                width: 100
              },
            ],
          },
        ],
        tableData: []
      }
      if (!this.isActiveEggPoultry) {
        data.columns.push({
          field: 'wMean',
          title: 'W. MEAN',
          fixed: window.innerWidth >= 1024 ? 'right' : false,
          children: [
            {
              field: 'wMeanContribution',
              key: uuidv4(),
              title: 'Contr.',
              width: 100
            },
            {
              field: 'wMeanMatrix',
              key: uuidv4(),
              title: 'Matrix',
              width: 100
            },
          ],
        })
      }

      const theadPhasesCol = (this.$store.state.feed.activeDiet.diet_phases || []).map((el, i) => {
        return {
          field: 'phase',
          id: el.id,
          title: el.name,
          children: [
            {
              key: uuidv4(),
              field: `phase${i}Contribution`,
              title: 'Contr.',
              width: 100
            },
            {
              key: uuidv4(),
              field: `phase${i}Matrix`,
              title: 'Matrix',
              width: 100
            },
          ]
        }
      })

      const tbodyCol = (this.$store.state.result.resultMatrix.finalResults || []).reduce((accum, el) => {
        const row = {...el}

        el.phases.forEach(phase => {
          const idx = theadPhasesCol.findIndex(el => el.id === phase.phase_id)

          if (idx >= 0) {
            row[`phase${idx}Contribution`] = phase.contribution

            row[`phase${idx}Matrix`] = phase.matrix
          }
        })

        return [...accum, row]
      }, [])

      // NOTE: thead phase columns
      data.columns.splice(1, 0, ...theadPhasesCol)

      // NOTE: tbody columns
      data.tableData.push(...tbodyCol)

      return data
    },
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.fixedTableHead)
  }
};
</script>

<style scoped>
>>> #grid-table thead {
  position: sticky;
  position: -webkit-sticky;
  z-index: 22;
  transition: top .5s ease 0s;
}
</style>
