<template>
  <div class="wrapper">
    <div class="bg-greenSustainability-light rounded-medium py-10 px-4 lg:px-10 2xl:p-14 mb-16">
      <h2 class="sub-title text-base text-2xl md:text-4xl text-left">
        Sustainability
      </h2>

      <div class="xl:grid xl:grid-cols-12">
        <div class="xl:col-start-1 xl:col-span-4 flex flex-col xl:border-r">
          <div class="mb-10 xl:mb-0 xl:pr-4 2xl:pr-10 xl:pb-5 grid grid-cols-12 xl:divide-x-0 lg:gap-10 xl:gap-0 items-start">
            <div class="col-span-12 lg:col-span-7 xl:col-span-12 mb-7">
              <div class="bg-white rounded-lg py-7 px-9">
                <form-field-input
                    v-model="model.liveWeight"
                    class="mb-3.5"
                    size="medium"
                    :field-label="animalType(activeDiet.animal_id).type === 'eggPoultry' ? 'Liveweight' : 'Final Liveweight'"
                    @input="reCalculate"
                >
                  <template v-slot:icon>(KG)</template>
                </form-field-input>
                <form-field-input
                    v-model="model.boilerCount"
                    size="medium"
                    :field-label="'Number of ' + displayAnimalType + ' raised per year'"
                    @input="reCalculate"
                    :isNeedFormatter="true"
                />
              </div>

              <div class="mt-10 text-center hidden lg:block xl:hidden">
                <span class="text-grey-dark text-lg">For more info, visit</span>
                <a href="https://www.sustell.com/" target="_blank">
                  <img src="@/assets/images/result/sustell.png" class="mx-auto mt-0.5" alt="sustell">
                </a>
              </div>
            </div>

            <div class="col-span-12 lg:col-span-5 xl:col-span-12 mx-4 xl:mx-9">
              <div>
                <div>
                  <img src="@/assets/images/result/goals.png" class="mx-auto">
                </div>

                <div class="py-7 xl:border-b">
                  <img src="@/assets/images/result/climateLife.png" class="mx-auto">
                </div>
              </div>

              <div class="text-center">
                <div class="grid">
                  <slither-slider :options="{autoplay:true, animatedDots: true, preserveGrid: true}">
                    <div class="bg-white p-1.5">
                      <img src="@/assets/images/result/sustainability/slide1.png" class="mx-auto">
                    </div>
                    <div class="bg-white p-1.5">
                      <img src="@/assets/images/result/sustainability/slide2.png" class="mx-auto">
                    </div>
                    <div class="bg-white p-1.5">
                      <img src="@/assets/images/result/sustainability/slide4.png" class="mx-auto">
                    </div>
                  </slither-slider>
                </div>
              </div>

              <div class="mt-7 text-center lg:hidden xl:block">
                <span class="text-grey-dark text-lg">For more info, visit</span>
                <a href="https://www.sustell.com/" target="_blank">
                  <img src="@/assets/images/result/sustell.png" class="mx-auto mt-0.5" alt="sustell">
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="xl:col-span-8 divide-y">
          <div class="flex justify-around md:justify-center items-center pb-8 lg:pb-14">
            <div class="grid md:grid-cols-2 gap-11 xl:ml-4">
              <div
                  v-for="(item, itemKey) in sustainability"
                  :key="itemKey"
                  class="flex grid grid-cols-12 bg-white rounded-lg p-5 pb-8 shadow-xl"
              >
                <div class="col-span-3 md:col-span-4">
                  <img :src="item.img" class="mx-auto">
                </div>
                <div class="col-span-8 md:col-span-7 pl-4 content-center flex flex-col justify-center">
                  <p
                      class="text-brand-dark text-lg lg:text-2xl mb-1 font-bold"
                      v-html="item.name"
                  >
                  </p>
                  <div class="flex items-center justify-left">
                    <span class="result text-2xl lg:text-medium-2xl mr-2.5 text-brand-semiMedium font-bold">{{ item.weight }}</span>
                    <span class="text-grey-dark text-xs md:text-sm" v-html="item.unit"></span>
                  </div>
                </div>
                <div
                    class="col-span-1 img-arrow"
                    :class="{'img-rotate': item.weight > 0}"
                >
                  <img src="@/assets/images/result/sustainability/arrow.png" class="mx-auto" alt="Sustainable development goals">
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-col justify-around md:justify-center items-center xl:space-x-4 2xl:space-x-12 pt-4 lg:pt-8">
            <div class="text-brand-dark text-xl mb-6 lg:mb-9">This is equivalent to...</div>

            <div class="grid grid-cols-1 mb-14">
              <div class="flex grid grid-cols-12 bg-white rounded-lg pb-10 pt-3 lg:py-10 px-8 shadow-xl divide-y lg:divide-y-0 lg:divide-x space-y-6 lg:space-y-0">
                <div
                    v-for="(item, itemKey) in sustainabilityEquivalent2"
                    :key="itemKey"
                    class="flex grid grid-cols-12 col-span-12 lg:col-span-4 items-center pt-6 lg:pt-0"
                >
                  <div class="2xl:col-start-2 pl-4 2xl:pl-0 col-span-3 2xl:col-span-2 justify-end flex">
                    <img :src="item.img" class="mx-auto">
                  </div>
                  <div class="col-span-9 pl-4 content-center flex flex-col justify-center">
                    <p
                        class="text-brand-dark text-base lg:text-sm mb-1 font-bold"
                        v-html="item.name"
                    >
                    </p>
                    <div class="flex items-center justify-left">
                      <span class="result text-2xl lg:text-medium-2xl mr-2.5 text-brand-semiMedium font-bold">{{ item.weight }}</span>
                    </div>
                    <div class="flex items-center justify-left">
                      <span class="text-xs mr-2.5 text-grey-dark">{{ item.help }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="grid md:grid-cols-2 gap-11 2xl:px-12">
              <div
                  v-for="(item, itemKey) in sustainabilityEquivalent"
                  :key="itemKey"
                  class="flex grid grid-cols-12 bg-white rounded-lg p-5 pb-8 shadow-xl"
              >
                <div class="col-span-3 md:col-span-4">
                  <img :src="item.img" class="mx-auto">
                </div>
                <div class="col-span-8 md:col-span-7 pl-4 content-center flex flex-col justify-center">
                  <p class="text-brand-dark text-lg lg:text-xl mb-1 font-bold">
                    <span class="flex-1">{{ item.name }}</span>
                    <span
                        class="icon help w-5 h-5 ml-1 my-auto"
                        v-html="icons.question"
                        v-tooltip="item.help">
                    </span>
                  </p>
                  <div class="flex items-center justify-left">
                    <span class="result text-2xl lg:text-medium-2xl mr-2.5 text-brand-semiMedium font-bold">{{ item.result }}</span>
                  </div>
                </div>
                <div
                    class="col-span-1 img-arrow"
                    :class="{'img-rotate': parseFloat(item.result) > 0}"
                >
                  <img src="@/assets/images/result/sustainability/arrow.png" class="mx-auto" alt="Sustainable development goals">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormFieldInput from '@/components/form/FormFieldInput'
import { mapGetters } from 'vuex';

export default {
  name: 'Sustainability',
  components: {
    FormFieldInput
  },
  props: {
    activeDiet: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    model: {
      liveWeight: 2.5,
      boilerCount: 10000000
    },
    dietBase: 'wheat',
    sustainabilityEquivalent2: {
      'car': {
        img: require('@/assets/images/result/sustainability/car.png'),
        name: 'The Greenhouse Gas Emmisions avoided by',
        weight: '2,513,198',
        help: 'Miles driven by an average passenger vehicle',
      },
      'phone': {
        img: require('@/assets/images/result/sustainability/phone.png'),
        name: 'CO<span class="align-sub text-xs">2</span> emissions from',
        weight: '121,652',
        help: 'Number of smartphones charged',
      },
      'house': {
        img: require('@/assets/images/result/sustainability/house.png'),
        name: 'CO<span class="align-sub text-xs">2</span> emissions from',
        weight: '120',
        help: 'Homes\' energy use for one year',
      },
    },
    sustainability: {
      'n_excretion': {
        img: require('@/assets/images/result/sustainability/n-excretion.png'),
        name: 'N excretion',
        weight: 5000,
        unit: 'kg N/kg'
      },
      'ch4_emissions': {
        img: require('@/assets/images/result/sustainability/ch4-emissions.png'),
        name: 'CH<span class="align-sub text-sm">4</span> emissions',
        weight: -5000,
        unit: 'kg CH<sub>4</sub>/kg'
      },
      'nh3_emissions': {
        img: require('@/assets/images/result/sustainability/nh3-emissions.png'),
        name: 'NH<span class="align-sub text-sm">3</span> emissions',
        weight: -5000,
        unit: 'kg NH<sub>3</sub>/kg'
      },
      'n2o_excretion': {
        img: require('@/assets/images/result/sustainability/h2o-excretion.png'),
        name: 'N<span class="align-sub text-sm">2</span>O emissions',
        weight: 5000,
        unit: 'kg N<sub>2</sub>O/kg'
      },
    },
    sustainabilityEquivalent: {
      'climate-change': {
        img: require('@/assets/images/result/sustainability/climate-change.png'),
        name: 'Climate Change',
        result: '-1.85%',
        help: `Climate change refers to the change in weather patterns due to change in average global temperature (global warming). 
        These changes have an impact on the quality of life on earth. Climate change is caused by various natural factors such as volcanic eruptions.
         Besides that, human activities have significant influence on climate change. Examples are fossil fuel combustion, agriculture and deforestation.
          Within LCA studies, for the impact category climate change only human activities are taken into account. These processes result in higher concentration of greenhouse gases (GHG’s) in the atmosphere. 
          CO2 is one of the greenhouse gases (GHG) that has an impact on climate change. The results here are expressed as kg CO2 equivalents. Other more potent greenhouse gases contribute to global warming, for instance methane (34 kg CO2 eq./kg) and nitrous oxide (298 kg CO2 as/kg). 
          In this DSM - Footprint tool, GHGs are estimated following IPCC (2006) guidelines, and calculated with the EF 2.0 method.`,
      },
      'acidification-terrestrial': {
        img: require('@/assets/images/result/sustainability/acidification-terrestrial.png'),
        name: 'Acidification terrestrial',
        result: '-3.10%',
        help: `Changes in acidity of the soil are caused by atmospheric deposition of acidic substances. 
        Serious changes are harmful for specific species. In the methodology three acidifying emissions are taken into account. 
        These emissions are NOx, NH3 and SO2. NOx is mainly formed during combustion processes. Agriculture is the main source for NH3. 
        And energy combustion (coal) counts mainly for SO2 emissions. The characterisation unit for this impact category is mol H+ equivalents,
         which is 0.74 for nitrogen oxides and 3.02 for ammonia.  Terrestrial acidification has impact on ecosystems in end-point results.`,
      },
    },
  }),

  mounted() {
    this.reCalculate();
  },

  methods: {
    reCalculate() {
      const nAdj = Math.round(this.baselineValues.n * this.adjustmentsByIngredient.n, 0)
      const ch4Adj = Math.round(this.baselineValues.ch4 * this.adjustmentsByIngredient.ch4, 0)
      const nh3Adj = Math.round(this.baselineValues.nh3 * this.adjustmentsByIngredient.nh3, 0)
      const n2oAdj = Math.round(this.baselineValues.n2o * this.adjustmentsByIngredient.n2o, 0)
      this.$set(this.sustainability.n_excretion, 'weight', new Intl.NumberFormat().format(nAdj))
      this.$set(this.sustainability.ch4_emissions, 'weight', new Intl.NumberFormat().format(ch4Adj))
      this.$set(this.sustainability.nh3_emissions, 'weight', new Intl.NumberFormat().format(nh3Adj))
      this.$set(this.sustainability.n2o_excretion, 'weight', new Intl.NumberFormat().format(n2oAdj))

      const co2equiv = Math.abs(n2oAdj) * 298 + Math.abs(ch4Adj) * 28 + Math.abs(nh3Adj) * 2.8

      const phoneEquiv = this.billionNumberHandling(co2equiv * 122)
      const carEquiv = this.billionNumberHandling(co2equiv * 2.5)

      this.sustainabilityEquivalent2.car.weight = carEquiv
      this.sustainabilityEquivalent2.phone.weight = phoneEquiv
      this.sustainabilityEquivalent2.house.weight = new Intl.NumberFormat().format(Math.round(co2equiv * 0.0001, 1))
    },

    billionNumberHandling(phoneEquiv) {
      if (phoneEquiv / 1000000000 > 1) {
        return `${Number.parseFloat(phoneEquiv / 1000000000).toFixed(2)} B`
      } else {
        return new Intl.NumberFormat().format(Math.round(phoneEquiv, 0))
      }
    },

    determineDietBase() {
      const rms = ['corn', 'wheat', 'sorghum', 'rice-broken', 'millet-pearl'];

      const dominant = {
        'unique_identifier': rms[0],
        'value': 0
      };
      for (const ingredient of this.activeDiet.dietPhases[0].ingredients) {
        if (rms.includes(ingredient.unique_identifier) && parseInt(ingredient.percentage) > parseInt(dominant.value)) {
          dominant.unique_identifier = ingredient.unique_identifier;
          dominant.value = parseInt(ingredient.percentage);
        }
      }

      this.dietBase = dominant.value !== 0 && dominant.unique_identifier === 'wheat' ? dominant.unique_identifier : 'other';
    }
  },


  computed: {
    ...mapGetters({
      animalType: 'animalType/animalTypeById'
    }),

    adjustmentsByIngredient() {
      switch (this.dietBase) {
        case 'wheat':
          return {
            'n': -9.4,
            'ch4': -5.0,
            'nh3': -9.4,
            'n2o': -9.3
          }
        case 'other':
          return {
            'n': -9.4,
            'ch4': -5.0,
            'nh3': -9.4,
            'n2o': -9.3
          }
      }
      return {}
    },

    totalLiveweight() {
      let animalQuantityNumber = this.model.boilerCount
      if (typeof animalQuantityNumber !== 'number') {
        animalQuantityNumber = Number(animalQuantityNumber.replaceAll(',', ''))
      }
      return this.model.liveWeight * animalQuantityNumber
    },

    baselineValues() {
      return {
        'n': 13.693 / 599 * this.totalLiveweight,
        'ch4': 0.696 / 599 * this.totalLiveweight,
        'nh3': 4.684 / 599 * this.totalLiveweight,
        'n2o': (0.022 + 0.102) / 599 * this.totalLiveweight
      }
    },

    displayAnimalType() {
      switch (this.animalType(this.activeDiet.animal_id).name) {
        case 'Fish':
          return 'fish'
        case 'Swine':
          return 'pigs'
        default:
          return this.animalType(this.activeDiet.animal_id).name
      }
    }
  }
};
</script>

<style scoped>
.align-sub {
  vertical-align: sub;
}
.img-arrow img {
  width: 16px;
  height: 22px;
}
.img-rotate img {
  transform: rotateX(180deg);
}
>>> .icon.help svg {
  @apply w-full h-full;
}
>>> .slither-slider {
  max-width: 353px;
  @apply mx-auto;
}
>>> .slither-slider-controls {
  @apply hidden;
}
>>> .slither-slider-dots {
  @apply bg-greenSustainability-light justify-center flex;
}
>>> .slither-slider-dots .slither-slider-dot {
  @apply bg-white border-grey-darkLight mx-1.5 mt-3 rounded-full;
  font-size: 0;
  width: 9px;
  height: 9px;
}
>>> .slither-slider-dots .slither-slider-dot.active-slide {
  @apply bg-green-light border-green-light;
}
</style>
